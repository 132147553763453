import React from "react";
import { useTranslation } from "react-i18next";
import { CustomForm } from "../../components/Form";
import useGetData from "../../hooks/useGetData";

const Iletisim = () => {
	const { t } = useTranslation();
	const { data }: any = useGetData("contact/");

	return (
		<div className='mb-24'>
			<iframe
				title='EATEC Construcion'
				src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d21498.891219260197!2d19.2306251!3d47.6579752!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741d3006a6a356b%3A0xb4895fb5a95730e9!2sEatec%20Construction%20Kft!5e0!3m2!1sen!2snl!4v1723927636068!5m2!1sen!2snl'
				className='w-full h-[325px] lg:h-[576px]'
				loading='lazy'
				referrerPolicy='no-referrer-when-downgrade'></iframe>

			<div className='flex flex-col items-center justify-center'>
				<h2 className='mt-8 text-2xl font-extrabold uppercase lg:text-3xl py-7'>{t("contact.title")}</h2>

				<div className='flex flex-col-reverse w-full px-4 sm:flex-row sm:px-30 lg:px-44'>
					<div className='flex flex-col w-full lg:w-1/2 sm:pt-0'>
						<div className='flex py-4 border-b-2 border-black sm:hidden' />

						<h5 className='text-base font-bold pt-14 sm:pt-0'>{t("contact.reachUs")}</h5>

						<CustomForm />
					</div>

					<div className='flex flex-col w-full lg:w-1/2 '>
						<div className='py-2 sm:py-4'>
							<h5 className='text-sm font-bold sm:text-base'>{t("footer.address")}</h5>
							<p className='text-sm font-normal sm:text-base'>{data[0]?.address || ""}</p>
						</div>
						<div className='py-2 sm:py-4'>
							<h5 className='text-sm font-bold sm:text-base'>{t("footer.phone")}</h5>
							<p className='text-sm font-normal sm:text-base'>{data[0]?.phone || ""}</p>
						</div>
						<div className='py-2 sm:py-4'>
							<h5 className='text-sm font-bold sm:text-base'>{t("footer.email")}</h5>
							<p className='text-sm font-normal sm:text-base'>{data[0]?.email || ""}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Iletisim;
