import React from "react";
import { API_BASE_URL } from "../../../../constants/api";
import { IGeneralData } from "../../../../constants/types";
import useGetData from "../../../../hooks/useGetData";

const Landing = () => {
	const { data }: IGeneralData = useGetData("header/");

	return (
		<div className='relative'>
			<img
				className='w-full h-[364px] sm:h-[416px] lg:h-[610px]'
				src={`${API_BASE_URL}${data[0]?.image}`}
				alt='constriction'
			/>
			<div className='absolute flex flex-col items-center justify-center w-full text-white lg:top-1/3 top-20'>
				<h1 className='graphiti text-4xl font-bold tracking-[0.75rem] lg:text-8xl lg:tracking-[1.5rem]'>
					{ data[0]?.title || ""}
				</h1>
				<div className='flex flex-col items-center w-4/5 mt-6 text-xs text-center bg-black md:w-4/5 lg:3/5 md:text-lg'>
					{data[0]?.description|| "" }
				</div>
			</div>
		</div>
	);
};

export default Landing;
