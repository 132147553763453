import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { Autoplay } from "swiper";
import "swiper/css";
import useGetData from "../../../../hooks/useGetData";
import { IBrand, IGeneralData } from "../../../../constants/types";
import { API_BASE_URL } from "../../../../constants/api";

const References = () => {
	const { t } = useTranslation();
	const { data }: IGeneralData = useGetData("brand/");

	return (
		<div className='flex flex-col items-center'>
			<div className='text-2xl font-extrabold leading-9 tracking-wide uppercase xl:py-4 lg:text-3xl'>
				{t("mainpage.references")}
			</div>

			<Swiper
				spaceBetween={50}
				slidesPerView={2}
				className='w-full xl:pb-10'
				autoplay={{ delay: 1500 }}
				modules={[Autoplay]}
				breakpoints={{
					640: {
						slidesPerView: 4,
					},
					900: {
						slidesPerView: 8,
					},
				}}>
				{data?.map((image: IBrand) => (
					<SwiperSlide key={image.id}>
						<img src={`${API_BASE_URL}${image?.image}`} alt='reference' />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default References;
