import { API_BASE_URL } from "../../constants/api";
import { useTranslation } from "react-i18next";
import useGetData from "../../hooks/useGetData";
import { IBrand } from "../../constants/types";

interface IProjectsProps {
	data?: IProject[];
	isLoaded?: boolean;
	errors?: string;
}

interface IProject {
	id?: number;
	title?: string;
	image?: string;
	language?: string;
}

const Projeler = () => {
	const { t } = useTranslation();
	const { data }: IProjectsProps = useGetData("project/");
	const references = useGetData("brand/");

	return (
		<>
			<div className='bg-[#333333] pt-8 pb-16 sm:py-14 lg:py-24 px-6 lg:px-14 relative'>
				<div className='text-5xl sm:text-6xl uppercase leading-[68px] strokeme text-transparent relative lg:pb-2'>
					{t("projects.projects")}
					<div className='bottom-0 hidden w-3/4 border-b-2 lg:block lg:absolute'></div>
				</div>
				<div className='relative grid grid-cols-2 gap-4 mt-4 sm:gap-5 lg:gap-8 sm:grid-cols-4 '>
					{data?.map((project: IProject) => (
						<div key={project?.id}>
							<img src={`${API_BASE_URL}${project?.image}`} alt={project?.title} className='w-full pb-3 sm:pb-4' />
							<div className='text-xs text-white sm:text-base'>{project?.title}</div>
						</div>
					))}
				</div>
				<div className='hidden w-3/4 border-b-2 right-14 bottom-14 lg:block lg:absolute'></div>
			</div>
			<div className='px-6 py-10 lg:p-16'>
				<div className='text-3xl font-extrabold text-center uppercase '>{t("projects.brands")}</div>
				<div className='grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 '>
					{references?.data?.map((reference: IBrand) => (
						<div key={reference?.id} className='flex justify-center'>
							<img src={`${API_BASE_URL}${reference?.image}`} alt='reference' />
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Projeler;
