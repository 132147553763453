import React from "react";
import { IHome } from "../../constants/types";
import Feher from "./components/Feher";
import Landing from "./components/Landing";
import References from "./components/References";
import Technics from "./components/Technics";

const Anasayfa = ({ setIsActive }: IHome) => {
	return (
		<div className='flex flex-col'>
			<Landing />
			<Technics setIsActive={setIsActive} />
			<Feher />
			<References />
		</div>
	);
};

export default Anasayfa;
