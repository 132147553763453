import { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Anasayfa from "./pages/Anasayfa";
import Iletisim from "./pages/Iletisim";
import Kurumsal from "./pages/Kurumsal";
import Projeler from "./pages/Projeler";
import Uygulamalar from "./pages/Uygulamalar";

const App = () => {
	const location = useLocation();
	const [language, setLanguage] = useState("en");
	const [isActive, setIsActive] = useState(location.pathname === "/" ? "mainpage" : location.pathname.substring(1));

	return (
		<>
			<Navbar language={language} setLanguage={setLanguage} isActive={isActive} setIsActive={setIsActive} />
			<Routes>
				<Route path='/' element={<Anasayfa setIsActive={setIsActive} />} />
				<Route path={"/corporate/"} element={<Kurumsal />} />
				<Route path={"applications/:technic/"} element={<Uygulamalar />} />
				<Route path={"projects/"} element={<Projeler />} />
				<Route path={"contact/"} element={<Iletisim />} />
			</Routes>
			<Footer setIsActive={setIsActive} />
		</>
	);
};

export default App;
