/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Button from "../../components/Button";
import SwiperTechnics from "../../components/SwiperTechnics";
import { API_BASE_URL } from "../../constants/api";
import { IGeneralData } from "../../constants/types";
import useGetData from "../../hooks/useGetData";

interface IApplication {
	id?: number;
	description?: string;
	image?: string;
	language?: string;
	link?: string;
	title?: string;
}

const Uygulamalar = () => {
	const { t } = useTranslation();
	const { data }:IGeneralData = useGetData("application/");
	const { technic } = useParams();

	return (
		<div className='flex flex-col'>
			<img
				className='w-full h-[280px] sm:h-[325px] lg:h-[560px]'
				src={`${API_BASE_URL}${data?.filter((tech: IApplication) => tech?.link === technic)[0]?.image}`}
				alt='selectedImage'
			/>
			<h1 className='py-8 text-2xl font-extrabold text-center uppercase lg:mt-6 lg:text-4xl'>{data?.filter((tech: IApplication) => tech?.link === technic)[0]?.title}</h1>

			<div className='flex flex-col items-center justify-between lg:mx-auto lg:w-4/5 lg:flex-row'>
				<p className='w-4/6 pb-5 mr-6 text-sm font-semibold lg:pb-10 lg:text-base'>{data?.filter((tech: IApplication) => tech?.link === technic)[0]?.description}</p>

				<img src={`${API_BASE_URL}${data?.filter((tech: IApplication) => tech?.link === technic)[0]?.image}`} className='w-[385px] h-[301px]' alt='small-images' />
			</div>

			<div className='flex flex-col items-center justify-center pt-9 lg:pt-40 pb-9'>
				<Button className="py-3"> {t("applications.title")} </Button>
				<SwiperTechnics />
			</div>
		</div>
	);
};

export default Uygulamalar;
