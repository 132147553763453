import React from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../constants/api";

interface ICard  {
	data: any;
};

const Card = ({ data }: ICard) => {
	return (
		<Link to={`/applications/${data?.link}` || `/applications/Acoustic-Ceiling`} className='relative flex flex-col p-6'>
			<img
				src={`${API_BASE_URL}${data?.image}`}
				alt='images'
				className='w-[232px] h-[180px] xl:w-[419px] xl:h-[324px]'
			/>
			<p className='absolute w-[232px] xl:w-[419px] text-center text-white bg-black bottom-6 py-3'> {data?.title} </p>
		</Link>
	);
};

export default Card;
