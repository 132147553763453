import React from "react";
import useGetData from "../../hooks/useGetData";
import { Link } from "react-router-dom";
import { ITechnics } from "../../constants/types";
import { API_BASE_URL } from "../../constants/api";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const SwiperTechnics = () => {
	const { data }: ITechnics = useGetData("application/");

	return (
		<>
			<Swiper
				slidesPerView={4}
				spaceBetween={30}
				slidesPerGroup={1}
				loop={false}
				loopFillGroupWithBlank={true}
				navigation={true}
				modules={[Navigation]}
				className='hidden w-screen mySwiper xl:flex'>
				{data?.map((technic: any) => (
					<SwiperSlide key={technic.id}>
						<Link
							to={
								`/applications/${technic?.link}` ||
								`/applications/Acoustic-Ceiling`
							}
							className='flex-col w-screen h-full p-6 lex '>
							<img src={`${API_BASE_URL}${technic?.image}`} alt='images' className='relative' />
						</Link>
					</SwiperSlide>
				))}
			</Swiper>

			<Swiper
				slidesPerView={3}
				spaceBetween={30}
				slidesPerGroup={1}
				loop={false}
				loopFillGroupWithBlank={true}
				navigation={true}
				modules={[Navigation]}
				className='hidden px-8 mySwiper md:flex xl:hidden'>
				{data?.map((technic: any) => (
					<SwiperSlide key={technic.id}>
						<Link
							to={
								`/applications/${technic?.link}` ||
								`/applications/Acoustic-Ceiling`
							}
							className='flex-col w-screen h-full p-6 lex '>
							<img src={`${API_BASE_URL}${technic?.image}`} alt='images' className='relative' />
						</Link>
					</SwiperSlide>
				))}
			</Swiper>

			<Swiper
				slidesPerView={1.5}
				spaceBetween={0}
				pagination={{
					clickable: true,
					dynamicBullets: true,
				}}
				modules={[Pagination]}
				className='flex w-screen mySwiper md:hidden'>
				{data?.map((technic: any) => (
					<SwiperSlide key={technic.id}>
						<Link
							to={
								`/applications/${technic?.link}` ||
								`/applications/Acoustic-Ceiling`
							}
							className='flex-col w-screen h-full p-6 lex '>
							<img src={`${API_BASE_URL}${technic?.image}`} alt='images' className='relative' />
						</Link>
					</SwiperSlide>
				))}
			</Swiper>
		</>
	);
};

export default SwiperTechnics;
