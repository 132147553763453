import React from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "../../../../components/Card";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";
import useGetData from "../../../../hooks/useGetData";
import { API_BASE_URL } from "../../../../constants/api";
import { Link } from "react-router-dom";
import { IHome, ITechnics } from "../../../../constants/types";

const Technics = ({ setIsActive }: IHome) => {
	const { t } = useTranslation();
	const { data }: ITechnics = useGetData("application/");

	return (
		<div className='flex flex-col items-center'>
			<div className='pt-5 text-2xl font-extrabold leading-9 tracking-wide uppercase md:pt-20 md:text-3xl'>
				{t("navbar.applications").toLocaleUpperCase("en-EN")}
			</div>

			<div onClick={() => setIsActive("applications")} className='hidden grid-cols-3 px-6 md:grid gap-x-10'>
				{data?.map((technic) => (
					<Card key={technic.id} data={technic} />
				))}
			</div>

			<Swiper
				slidesPerView={1.25}
				spaceBetween={0}
				pagination={{
					clickable: true,
					dynamicBullets: true,
				}}
				modules={[Pagination]}
				className='flex w-screen mySwiper md:hidden'>
				{data?.map((technic: any) => (
					<SwiperSlide key={technic.id}>
						<Link onClick={()=> setIsActive("applications")} to={`/applications/${technic?.link}/`} className='relative flex flex-col p-6 h-[210px] w-[332px]'>
							<img src={`${API_BASE_URL}${technic?.image}`} alt='images' className='' />
							<p className='absolute w-[282px] py-2 text-center text-sm text-white bg-black bottom-6'>
								{technic?.title}
							</p>
						</Link>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default Technics;
