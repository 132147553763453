import React from "react";

type IButton = {
	children: any;
	className: string;
};

const Button = ({ children, className }: IButton) => {
	return (
		<div className={`flex items-center justify-center py-1 text-white bg-black px-9 ${className}`}>{children}</div>
	);
};

export default Button;
