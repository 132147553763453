import axios from "axios";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { API_BASE_URL } from "../constants/api";

const useGetData = <T>(url: string) => {
	const [data, setData] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const [error, setError] = useState(null);
	const lang = i18next.language;

	useEffect(() => {
		const fetchData = () => {
			axios
				.get(`${API_BASE_URL}/${url}${lang === "en" ? "listEn" : "listHu"}/`)
				.then((response) => {
					setIsLoaded(true);
					setData(response.data);
				})
				.catch((error) => {
					setError(error);
				});
		};
		fetchData();
	}, [url, lang]);

	return { error, isLoaded, data };
};

export default useGetData;
