import { API_BASE_URL } from "../../constants/api";
import useGetData from "../../hooks/useGetData";

const Kurumsal = () => {
	const { data } = useGetData("our-policies/");
	const topContent: any = useGetData("homepage/");
	console.log(data);

	return (
		<div lang='tr'>
			<div className='flex flex-col-reverse relative sm:flex-row bg-[#333333] p-4 sm:p-6 lg:p-14 text-white '>
				<div className='hidden w-3/4 border-t-2 sm:block sm:absolute lg:hidden top-7'></div>
				<div className='w-full pt-4 pr-4 text-xs text-justify sm:text-sm xl:pt-20 lg:border-t-2 sm:w-1/2 lg:text-lg'>
					{topContent?.data[0]?.description || ""}
				</div>
				<div className='flex items-start justify-center w-full pt-6 border-t-2 sm:border-t-0 lg:pt-0 sm:pl-4 sm:w-1/2 '>
					<img
						src={`${API_BASE_URL}${topContent?.data[0]?.image}`}
						alt={topContent?.data[0]?.title}
						className='w-[343px] h-[349px] lg:w-[644px] lg:h-[440px]'
					/>
				</div>
			</div>
			{data.map((policy: any) => (
				<div className='p-4 mx-auto text-sm xl:w-4/5 sm:p-6 lg:text-base lg:p-14' key={policy.id}>
					<div className='text-center text-[#040301] font-extrabold text-xl lg:text-4xl tracking-wide uppercase'>
						{policy.title.toLocaleUpperCase("en-EN")}
					</div>
					<div
						className={`flex flex-col sm:flex-row justify-between mt-8 ${
							(policy.title === "Quality Policy" || policy.title === "Minőségpolitika") && "sm:flex-row-reverse"
						}`}>
						<div className='w-full sm:w-[65%] lg:w-[60%] relative flex items-center'>
							<div className='w-4/5 mx-auto'>{policy.description}</div>

							<div className='hidden sm:absolute bottom-[-20px] lg:bottom-0 w-2/3 border-b-2 border-black '></div>
						</div>
						<div className=' w-full my-6 sm:my-0 sm:w-[30%] lg:w-[35%]  justify-center flex items-start'>
							<img src={`${API_BASE_URL}${policy?.image}`} alt='policy-images' />
						</div>
					</div>
					<div className='w-full border-b-2 border-black sm:hidden'></div>
				</div>
			))}
		</div>
	);
};

export default Kurumsal;
