import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import EnLanguage from "./translation/En.json";
import HnLanguage from "./translation/Hu.json";

i18next
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			en: {
				translation: EnLanguage,
			},

			hu: {
				translation: HnLanguage,
			},
		},
		lng: "en",
		fallbackLng: "en",

		interpolation: {
			escapeValue: false,
		},
	});

export default i18next;
