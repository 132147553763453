import * as React from "react";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_BASE_URL } from "../../constants/api";

interface MyFormValues {
	name: string;
	phone: string;
	email: string;
	message: string;
}

export const CustomForm: React.FC<{}> = () => {
	const { t } = useTranslation();
	const initialValues: MyFormValues = {
		name: "",
		phone: "",
		email: "",
		message: "",
	};

	return (
		<div>
			<Formik
				initialValues={initialValues}
				onSubmit={async (values, { resetForm }) => {
					axios.post(`${API_BASE_URL}/contactform`, values);
					alert("You will be informed via mail. ");
					resetForm();
				}}>
				<Form>
					<div className='flex flex-col py-2 sm:py-4'>
						<label className='py-1 text-sm sm:text-base' htmlFor='name'>
							{t("form.name")}
						</label>
						<Field
							required
							className='p-1 border outline-none sm:p-2 sm:mr-8'
							id='name'
							name='name'
							placeholder={t("form.name")}
						/>
					</div>

					<div className='flex flex-col py-2 sm:py-4'>
						<label className='py-1 text-sm sm:text-base' htmlFor='phone'>
							{t("form.phone")}
						</label>
						<Field
							required
							className='p-1 border outline-none sm:p-2 sm:mr-8'
							id='phone'
							name='phone'
							placeholder={t("form.phone")}
						/>
					</div>

					<div className='flex flex-col py-2 sm:py-4'>
						<label className='py-1 text-sm sm:text-base' htmlFor='email'>
							{t("form.email")}
						</label>
						<Field
							required
							className='p-1 border outline-none sm:p-2 sm:mr-8'
							id='email'
							name='email'
							placeholder={t("form.email")}
						/>
					</div>

					<div className='flex flex-col py-2 sm:py-4'>
						<label className='py-1 text-sm sm:text-base' htmlFor='message'>
							{t("form.message")}
						</label>
						<Field
							required
							className='p-1 border outline-none sm:p-2 sm:mr-8'
							id='message'
							name='message'
							placeholder={t("form.message")}
						/>
					</div>

					<div className='flex flex-col py-2 sm:py-4'>
						<button
							className='w-3/4 px-4 py-2 mx-auto mt-5 mr-8 text-white uppercase bg-black border sm:w-1/4 sm:mt-0'
							type='submit'>
							{t("form.submit")}
						</button>
					</div>

					<div className='hidden pt-8 pb-4 border-b-2 border-black sm:flex' />
				</Form>
			</Formik>
		</div>
	);
};
