import React from "react";
import { API_BASE_URL } from "../../../../constants/api";
import useGetData from "../../../../hooks/useGetData";

const Feher = () => {
	const { data }: any = useGetData("homepage/");
	
	return (
		<div className='flex flex-col items-center justify-center w-full h-full py-10 mt-24 mb-16 xl:items-start bg-dark lg:pr-16 lg:pl-36'>
			<h2 className='sm:border-b-4 pb-4 mt-8 xl:mt-20 w-max px-6 uppercase graphiti text-2xl sm:text-4xl xl:text-8xl font-bold tracking-[0.35rem] lg:tracking-[1rem]' style={{ color: '#119992' }}>
				{data[0]?.title || ""}
			</h2>
			<div className='flex flex-col-reverse items-center py-6 lg:flex-row '>
				<p className='px-6 py-2 text-sm text-white xl:pr-40 sm:text-base xl:text-xl xl:w-5/6'>
					{data[0]?.description || ""}
				</p>
				<img
					src={`${API_BASE_URL}${data[0]?.image}`}
					alt='eatec-construction'
					className='p-6 h-[255px] sm:h-[289px] xl:h-[483px]'
				/>
			</div>
		</div>
	);
};

export default Feher;
